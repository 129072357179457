<template>
  <div class="review">
    <img class="background" src="../../assets/image/background.png" alt srcset />
    <header>
      <img src="../../assets/image/logo.png" alt srcset />
    </header>
    <div class="index" v-if="index==1">
      <div class="card">
        <div class="title">2012年首届中国罕见病高峰论坛</div>
        <div class="address">2012年2月29日是第五个国际罕见病日，瓷娃娃罕见病关爱中心主办了“2012首届中国罕见病高峰论坛”。</div>
      </div>
      <div class="images">
        <div class="image">
          <img src="../../assets/review/1-1.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/1-2.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/1-3.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/1-4.jpg" alt />
        </div>
      </div>
    </div>
    <div class="index" v-if="index==2">
      <div class="card">
        <div class="title">2013第二届中国罕见病高峰论坛</div>
        <div class="address">
          2013年2月28日是第六个国际罕见病日，
          瓷娃娃罕见病关爱中心和北京医学会医学遗传学分会、北京医学会罕见病分会、
          上海市医学会罕见病专科分会、山东省罕见疾病防治协会、广东省医学会罕见病分会（筹）
          共同主办了2013第二届中国罕见病高峰论坛。
        </div>
      </div>
      <div class="images">
        <div class="image">
          <img src="../../assets/review/2-1.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/2-2.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/2-3.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/2-4.jpg" alt />
        </div>
      </div>
    </div>
    <div class="index" v-if="index==3">
      <div class="card">
        <div class="title">2014第三届中国罕见病高峰论坛</div>
        <div class="address">
          2014年9月21日，由蔻德罕见病中心（CORD，原罕见病发展中心）与北京医学会罕见病分会联合主办的“2014第三届中国罕见病高峰论坛”
          在中国科技会堂隆重举行。参会者两百多人，关注的媒体有三十多家。
        </div>
      </div>
      <div class="images">
        <div class="image">
          <img src="../../assets/review/3-1.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/3-2.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/3-3.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/3-4.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/3-5.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/3-6.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/3-7.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/3-8.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/3-9.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/3-10.jpg" alt />
        </div>
      </div>
    </div>
    <div class="index" v-if="index==4">
      <div class="card">
        <div class="title">2015第四届中国罕见病高峰论坛</div>
        <div class="address">
          2015年9月18日，由蔻德罕见病中心（CORD，原罕见病发展中心）主办，
          同济大学附属第一妇婴保健院及北京大学医药管理国际研究中心联合主办的“2015第四届中国罕见病高峰论坛”在北京隆重举行。
          本次大会超过四百人参与，四十多家媒体关注和报道。
        </div>
      </div>
      <div class="images">
        <div class="image">
          <img src="../../assets/review/4-1.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/4-2.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/4-3.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/4-4.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/4-5.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/4-6.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/4-7.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/4-8.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/4-9.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/4-10.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/4-11.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/4-12.jpg" alt />
        </div>
      </div>
    </div>
    <div class="index" v-if="index==5">
      <div class="card">
        <div class="title">2016第五届中国罕见病高峰论坛</div>
        <div class="address">
          2016年9月23日，由蔻德罕见病中心（CORD，原罕见病发展中心）、
          树兰医疗联合主办的2016年第五届中国罕见病高峰论坛在杭州隆重开幕。在大会现场，
          来自政府、医学行业、医药企业、基金会等不同领域的专家、学者，
          以及病友组织代表、患者及家属、社会爱心人士、媒体朋友共计五百多人出席本次大会。
        </div>
        <div class="address">
          通过该论坛主办方希望可以加强各利益相关方之间的交流与合作，增进社会各界对罕见病问题的关注和认识，
          从诊治技术、孤儿药、政策等不同层面推动中国罕见病事业的发展。
        </div>
        <div class="address">
          蔻德罕见病中心（CORD，原罕见病发展中心）正式宣布取得了ICORD2017中国年会的主办权，这是该会议首次来到中国举办。
          同时发布了《中国罕见病参考名录》，以为国家及各省市相关部门制定罕见病政策提供参考，
          为各方在罕见病的科研、诊治、药物研发及市场准入、医疗保障以及社会救助等方面提供优先参考。
        </div>
      </div>
      <div class="images">
        <div class="image">
          <img src="../../assets/review/5-1.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/5-2.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/5-3.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/5-4.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/5-5.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/5-6.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/5-7.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/5-8.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/5-9.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/5-10.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/5-11.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/5-12.jpg" alt />
        </div>
      </div>
    </div>
    <div class="index" v-if="index==6">
      <div class="card">
        <div class="title">第十二届国际罕见病与孤儿药大会暨第六届中国罕见病高峰论坛</div>
        <div class="address">
          2017年9月8日，由国际罕见病与孤儿药大会（ICORD）、蔻德罕见病中心（CORD，原罕见病发展中心）、
          北京协和医院（PUMCH）共同举办的“第十二届国际罕见病与孤儿药大会暨第六届中国罕见病高峰论坛”在北京隆重开幕。
          大会现场，来自政府、科研机构、医学行业、医药企业、基金会等不同领域的120位专家、学者，
          以及病友组织代表、患者及家属、社会爱心人士、媒体朋友共计超过八百人出席本次大会。
        </div>
        <div class="address">
          这是ICORD年会首次进入中国，中国在全球罕见病的工作中都扮演着至关重要的角色，我们不仅要迅速发展自己，
          也有责任与世界各国、各地区享相互扶持、共同进步，为全球罕见病事业的发展贡献出自己的一份力量，全球的目光都期待着中国要带来的惊喜！
          在大会中安排了众多特色的内容，紧紧围绕着“罕见病的全球挑战与中国角色”这一主题展开，共划分二十多个专题，设置了一百二十多个主题发言。
        </div>
      </div>
      <div class="images">
        <div class="image">
          <img src="../../assets/review/6-1.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/6-2.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/6-3.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/6-4.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/6-5.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/6-6.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/6-7.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/6-8.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/6-9.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/6-10.jpg" alt />
        </div>
      </div>
    </div>
    <div class="index" v-if="index==7">
      <div class="card">
        <div class="title">第七届中国罕见病高峰论坛</div>
        <div class="address">
          2018年9月14日-16日，由蔻德罕见病中心（CORD，原罕见病发展中心）、
          复旦大学附属儿科医院（国家儿童医学中心）共同主办的“第七届中国罕见病高峰论坛”在上海隆重召开。
          本届论坛围绕“合作与创新-让爱不罕见”展开，会议期间来自医学科研机构、医药行业、社会公益、
          公众媒体等领域的专家学者、罕见病患者及家属、社会爱心人士等五百余名国内外参会代表汇聚一堂，
          共同为国内罕见病医学研究、药物研发、社会关爱等领域发展建言献策。这是一场意义非凡的盛会，
          促进各方合作与交流， 为改善罕见病患者生活质量、提升罕见病诊疗能力、加快孤儿药研发速度，
          搭建起资源共享、多向互动的罕见病发展平台。
        </div>
      </div>
      <div class="images">
        <div class="image">
          <img src="../../assets/review/7-1.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/7-2.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/7-3.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/7-4.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/7-5.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/7-6.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/7-7.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/7-8.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/7-9.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/7-10.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/7-11.jpg" alt />
        </div>
      </div>
    </div>
    <div class="index" v-if="index==8">
      <div class="card">
        <div class="title">2019第八届中国罕见病高峰论坛</div>
        <div class="address">
          由蔻德罕见病中心（CORD，原罕见病发展中心）主办、深圳市医学会支持的2019第八届中国罕见病高峰论坛在深圳隆重召开，
          来自国内外罕见病领域的专家学者、临床医生、医药企业、测序机构、政府部门等不同领域的110多位专家、学者，
          以及病友组织代表、患者及家属、社会爱心人士、媒体朋友等超过900人参加大会，
          共同为罕见病医学研究、药物研发、社会关爱等领域发展建言献策。
        </div>
      </div>
      <div class="images">
        <div class="image">
          <img src="../../assets/review/8-1.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/8-2.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/8-3.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/8-4.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/8-5.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/8-6.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/8-7.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/8-8.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/8-9.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/8-10.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/8-11.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/8-12.jpg" alt />
        </div>
      </div>
    </div>
    <div class="index" v-if="index==9">
      <div class="card">
        <div class="title">2020第九届中国罕见病高峰论坛</div>
        <div class="address">
          由蔻德罕见病中心（CORD，原罕见病发展中心）、四川大学华西医院联合主办的2020第九届中国罕见病高峰论坛在成都举行。
          来自国内外罕见病领域的专家学者、临床医生、医药企业、测序机构、政府部门等不同领域的100余位专家、学者，
          病友组织代表等参加线下大会， 共同为罕见病医学研究、医保探索、社会关爱等领域发展建言献策。
        </div>
      </div>
      <div class="images">
        <div class="image">
          <img src="../../assets/review/9-1.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/9-2.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/9-3.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/9-4.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/9-5.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/9-6.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/9-7.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/9-8.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/9-9.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/9-10.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/9-11.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/9-12.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/9-13.jpg" alt />
        </div>
      </div>
    </div>
    <div class="index" v-if="index==10">
      <div class="card">
        <div class="title">2021年第十届中国罕见病高峰论坛</div>
        <!-- <div class="address">2012年2月29日是第五个国际罕见病日，瓷娃娃罕见病关爱中心主办了2012第首届中国罕见病高峰论坛。</div> -->
      </div>
      <div class="images">
        <!-- <div class="image">
          <img src="../../assets/review/1-1.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/1-2.jpg" alt />
        </div>
        <div class="image">
          <img src="../../assets/review/1-5.jpg" alt />
        </div>-->
      </div>
    </div>
    <div class="footer-fill"></div>
    <el-footer :index="1" />
  </div>
</template>

<script>
import footer from "../../components/footer/footer-tempate.vue";
export default {
  components: {
    "el-footer": footer
  },
  data() {
    return {
      index: 0
    };
  },
  methods: {},
  created() {
    this.index = this.$route.params.index;
  }
};
</script>

<style lang="less" scoped>
.review {
  min-height: 100vh;
  // background-image: url("../../assets/image/background.png");
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  .background {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    position: fixed;
    background-size: 100% 100%;
    z-index: -1;
  }
  header {
    padding: 10px 0;
    width: 80%;
    margin: 0 auto;
    img {
      max-width: 100%;
    }
  }
  .card {
    width: 92%;
    margin: 0 4% 12px 4%;
    padding: 20px 12px;
    box-sizing: border-box;
    // height: 140px;
    color: #fff;
    background-image: url("../../assets/image/bg.png");
    border: #fff solid 1px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .title {
      font-size: 16px;
      font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
      font-weight: 400;
      text-align: center;
    }
    .address {
      margin-top: 12px;
      font-size: 14px;
    }
  }
  .images {
    width: 90%;
    margin: 0 auto;
    .image {
      img {
        width: 100%;
      }
    }
  }
}
.footer-fill {
  height: 37px;
}
</style>