<template>
  <div class="footer">
    <!-- <button  :class="active == index ? 'active':'buttons'" v-for="(item, index) in list" :key="index" @click="switchover(index)" >
      {{ item.name }}
    </button>-->
    <van-tabbar class="footer-tabbar" route :placeholder="true">
      <van-tabbar-item replace to="/home" :class="index==1?'van-tabbar-item--active':''">首页</van-tabbar-item>
      <van-tabbar-item replace to="/user" :class="index==2?'van-tabbar-item--active':''">个人中心</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      default: 0
    },
    list: {
      type: Array
    }
  },
  methods: {
    switchover(index) {
      this.$emit("switchover", index);
      this.Jump(index);
    },
    Jump(index) {
      let url = this.list[index].url;
      if (url == "") {
        return false;
      } else {
        this.$router.push(url);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.footer {
  position: fixed;
  display: flex;
  -webkit-overflow-scroll:touch;
  bottom: 0;
  width: 100%;
  height: 75px;
  .footer-tabbar {
    height: 37.5px;
    font-size: 15px;
    /deep/ .van-tabbar-item {
      background-color: #767676;
      color: #444444;
      border:none;
    }
    /deep/ .van-tabbar-item--active {
      background-color: #f0906d;
      color: #ffffff;
      border: none;
    }
  }
}
</style>